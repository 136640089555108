<template>
  <div>
    <b-row class="no-gutters px-3 px-sm-0">
      <b-col md="8">
        <h1 class="mr-sm-4 header-tablepage">Setting</h1>
      </b-col>
    </b-row>
    <b-container class="no-gutters p-3 mt-3" style="background-color: white">
      <b-row class="px-3">
        <b-col
          v-for="items of menu"
          cols="6"
          class="pointer my-3"
          @click.prevent="$router.push(items.path)"
          :key="items.id"
        >
          <div class="bg-icon">
            <font-awesome-icon :icon="items.icon" size="lg" />
          </div>
          <div class="content">
            <div class="ft-weight" style="text-transform: uppercase">
              {{ items.title }}
            </div>
            <div class="ft-10">{{ items.desc }}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          id: 0,
          title: "MemberShip",
          desc: "การตั้งค่ารหัสสมาชิก, ระดับสมาชิก , Member Prefix ID",
          path: "/setting/membership",
          icon: "copy",
        },
        {
          id: 1,
          title: "line setting",
          desc: "การตั้งค่าข้อเสนอและนโยบายของระบบ",
          path: "/setting/line",
          icon: "lock",
        },

        {
          id: 2,
          title: "theme",
          desc: "การปรับรูปแบบอัตลักษณ์, สี, รูปแบบอักษร, ทั้งหมดของระบบ",
          path: "/setting/theme",
          icon: "palette",
        },
        {
          id: 3,
          title: "Business Information",
          desc: "การตั้งค่าและเก็บข้อมูลสาขา, ข้อมูลที่ตั้งสาขา, พนักงานในร้าน",
          path: "/setting/bussinessinfo",
          icon: "info-circle",
        },

        {
          id: 4,
          title: "Pdpa email template",
          desc: "การตั้งค่าระบบอีเมล สำหรับรองรับการเข้าถึงข้อมูลส่วนบุคคล",
          path: "/setting/pdpasetting",
          icon: "file-invoice",
        },
        // {
        //   id: 5,
        //   title: "E-Commerce",
        //   desc: "เชื่อมต่อระบบ Marketplace",
        //   path: "/setting/ecommerce",
        //   icon: "file-invoice",
        // },
        {
          id: 6,
          title: "Term and condition",
          desc: "รายละเอียดเงื่อนไขและนโยบาย",
          path: "/update/term",
          icon: "file-invoice",
        },
        {
          id: 7,
          title: "PRIVACY POLICY",
          desc: "รายละเอียดการตั้งค่าความเป็นส่วนตัว",
          path: "/update/policy",
          icon: "file-invoice",
        },
        // {
        //   id: 5,
        //   title: "E-Commerce",
        //   desc: "เชื่อมต่อระบบ Marketplace",
        //   path: "/setting/ecommerce",
        //   icon: "file-invoice",
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-icon {
  background-color: var(--secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  color: var(--primary-color);
  padding: 15px;
  text-align: center;
}
.content {
  position: absolute;
  top: 5px;
  margin-left: 60px;
}
.ft-10 {
  font-size: 10px;
}
.ft-weight {
  font-weight: 600;
}
</style>
